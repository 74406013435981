export default {
	GatewayLinksBarEcommerceV2: defineAsyncComponent(() => import("~/components/ecommerce/GatewayLinksBarEcommerceV2.vue")),
	AddressPropertySearch: defineAsyncComponent(() => import("~/components/broadband-consumer/AddressSearch/AddressPropertySearch.vue")),
	AddressSearch: defineAsyncComponent(() => import("~/components/broadband-consumer/AddressSearch/AddressSearch.vue")),
	AddressSearchResults: defineAsyncComponent(() => import("~/components/broadband-consumer/AddressSearchResults/AddressSearchResults.vue")),
	FiberProjectResults: defineAsyncComponent(() => import("~/components/broadband-consumer/FiberProjectResults/FiberProjectResults.vue")),
	GenericTile: defineAsyncComponent(() => import("~/components/broadband-consumer/GenericTile/GenericTile.vue")),
	GizmoFixedInfoWrapper: defineAsyncComponent(() => import("~/components/broadband-consumer/GizmoFixedInfoWrapper/GizmoFixedInfoWrapper.vue")),
	ProductFeasibilityResults: defineAsyncComponent(() => import("~/components/business/BusinessProductFeasibilityResults/ProductFeasibilityResults.vue")),
	CTIDateBooker: defineAsyncComponent(() => import("~/components/business/CTIDateBooker/CTIDateBooker.vue")),
	SafezoneGraph: defineAsyncComponent(() => import("~/components/business/SafezoneGraph/SafezoneGraph.vue")),
	SafezoneGraphAdvanced: defineAsyncComponent(() => import("~/components/business/SafezoneGraphAdvanced/SafezoneGraphAdvanced.vue")),
	DMFMapSearch: defineAsyncComponent(() => import("~/components/dmf/DMFMapSearch/DMFMapSearch.vue")),
	DMFRegistryBoard: defineAsyncComponent(() => import("~/components/dmf/DMFRegistryBoard/DMFRegistryBoard.vue")),
	MapSearch: defineAsyncComponent(() => import("~/components/dmf/MapSearch/MapSearch.vue")),
	DealerSearch: defineAsyncComponent(() => import("~/components/ecommerce/DealerSearch/DealerSearch.vue")),
	GizmoECommerceAddToCartStickyButton: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceAddToCartStickyButton/GizmoECommerceAddToCartStickyButton.vue")),
	GizmoECommerceCardWrapper: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceCardWrapper/GizmoECommerceCardWrapper.vue")),
	GizmoECommerceClickAndCollect: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceClickAndCollect/GizmoECommerceClickAndCollect.vue")),
	ECommerceDeliveryOptions: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceDeliveryOptions/ECommerceDeliveryOptions.vue")),
	ECommerceGradingSelector: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceGradingSelector/ECommerceGradingSelector.vue")),
	ECommerceIdentification: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceIdentification/ECommerceIdentification.vue")),
	ECommerceKeepSubscription: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceKeepSubscription/ECommerceKeepSubscription.vue")),
	ECommerceLogistics: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceLogistics/ECommerceLogistics.vue")),
	ECommerceOptionsIcons: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceOptionsIcons/ECommerceOptionsIcons.vue")),
	ECommerceOrderButton: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceOrderButton/ECommerceOrderButton.vue")),
	ECommerceOrderSummary: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceOrderSummary/ECommerceOrderSummary.vue")),
	GizmoECommercePhysicalSizeSelector: defineAsyncComponent(() => import("~/components/ecommerce/ECommercePhysicalSizeSelector/GizmoECommercePhysicalSizeSelector.vue")),
	ECommercePriceOptions: defineAsyncComponent(() => import("~/components/ecommerce/ECommercePriceOptions/ECommercePriceOptions.vue")),
	ECommerceProductAccessories: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductAccessories/ECommerceProductAccessories.vue")),
	ECommerceProductCard: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductCard/ECommerceProductCard.vue")),
	ECommerceProductCardV2: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductCardV2/ECommerceProductCardV2.vue")),
	ECommerceProductColorPicker: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductColorPicker/ECommerceProductColorPicker.vue")),
	ECommerceProductDescription: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductDescription/ECommerceProductDescription.vue")),
	ECommerceProductDetails: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductDetails/ECommerceProductDetails.vue")),
	ECommerceProductImageCarousel: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductImageCarousel/ECommerceProductImageCarousel.vue")),
	ECommerceProductPaymentTypeSelector: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductPaymentTypeSelector/ECommerceProductPaymentTypeSelector.vue")),
	ECommerceProductRating: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductRating/ECommerceProductRating.vue")),
	ECommerceProductTitle: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceProductTitle/ECommerceProductTitle.vue")),
	ECommerceRecommendedAccessory: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceRecommendedAccessory/ECommerceRecommendedAccessory.vue")),
	GizmoECommerceShoppingCartDynamicContentWrapper: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceShoppingCartDynamicContentWrapper/GizmoECommerceShoppingCartDynamicContentWrapper.vue")),
	ECommerceStepsIndicator: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceStepsIndicator/ECommerceStepsIndicator.vue")),
	ECommerceStorageSelector: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceStorageSelector/ECommerceStorageSelector.vue")),
	ECommerceStoreStock: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceStoreStock/ECommerceStoreStock.vue")),
	ECommerceSubscriptionPicker: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceSubscriptionPicker/ECommerceSubscriptionPicker.vue")),
	ECommerceUSPSection: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceUSPSection/ECommerceUSPSection.vue")),
	ECommerceWrapper: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceWrapper/ECommerceWrapper.vue")),
	GizmoECommereceSubscriptionInformation: defineAsyncComponent(() => import("~/components/ecommerce/ECommereceSubscriptionInformation/GizmoECommereceSubscriptionInformation.vue")),
	GizmoCheckoutChoosePaymentType: defineAsyncComponent(() => import("~/components/ecommerce/GizmoCheckoutChoosePaymentType/GizmoCheckoutChoosePaymentType.vue")),
	ECommerceCheckoutConfigItemHeader: defineAsyncComponent(() => import("~/components/ecommerce/checkout/ECommerceCheckoutConfigItemHeader.vue")),
	ECommerceCheckoutSummary: defineAsyncComponent(() => import("~/components/ecommerce/checkout/ECommerceCheckoutSummary.vue")),
	GizmoCostShareCategory: defineAsyncComponent(() => import("~/components/employee-administration/GizmoCostShareCategory/GizmoCostShareCategory.vue")),
	GizmoCostShareDetails: defineAsyncComponent(() => import("~/components/employee-administration/GizmoCostShareDetails/GizmoCostShareDetails.vue")),
	GizmoCostShareNotifications: defineAsyncComponent(() => import("~/components/employee-administration/GizmoCostShareNotifications/GizmoCostShareNotifications.vue")),
	GizmoCostShareOverview: defineAsyncComponent(() => import("~/components/employee-administration/GizmoCostShareOverview/GizmoCostShareOverview.vue")),
	GizmoCostShareRule: defineAsyncComponent(() => import("~/components/employee-administration/GizmoCostShareRule/GizmoCostShareRule.vue")),
	OrderConsent: defineAsyncComponent(() => import("~/components/hardware-and-insurance/consent/OrderConsent.vue")),
	GizmoHelloWorld: defineAsyncComponent(() => import("~/components/mobile-business/GizmoHelloWorld/GizmoHelloWorld.vue")),
	RoamingPricesFasttelefoni: defineAsyncComponent(() => import("~/components/portfolio/CallAbroadFromLandline/RoamingPricesFasttelefoni.vue")),
	GizmoMobileSubscriptionTabs: defineAsyncComponent(() => import("~/components/portfolio/GizmoMobileSubscriptionTabs/GizmoMobileSubscriptionTabs.vue")),
	GizmoSubscriptionCart: defineAsyncComponent(() => import("~/components/portfolio/GizmoSubscriptionCart/GizmoSubscriptionCart.vue")),
	GizmoSubscriptionTable: defineAsyncComponent(() => import("~/components/portfolio/GizmoSubscriptionTable/GizmoSubscriptionTable.vue")),
	MobileSubscriptionTable: defineAsyncComponent(() => import("~/components/portfolio/GizmoSubscriptionTable/MobileSubscriptionTable.vue")),
	GizmoSubscriptionCardsV2: defineAsyncComponent(() => import("~/components/portfolio/SubscriptionCardsV2/GizmoSubscriptionCardsV2.vue")),
	RoamingPricesMobile: defineAsyncComponent(() => import("~/components/portfolio/roaming/RoamingPricesMobile.vue")),
	DynamicLogin: defineAsyncComponent(() => import("~/components/security-consumer/DynamicLogin/DynamicLogin.vue")),
	EmailBreachCheck: defineAsyncComponent(() => import("~/components/security-consumer/EmailBreachCheck/EmailBreachCheck.vue")),
	OnlineEmailPasswordReset: defineAsyncComponent(() => import("~/components/security-consumer/OnlineEmailPasswordReset/OnlineEmailPasswordReset.vue")),
	ErrorSearch: defineAsyncComponent(() => import("~/components/tv/ErrorSearch/ErrorSearch.vue")),
	ListTvChannels: defineAsyncComponent(() => import("~/components/tv/ListTvChannels/ListTvChannels.vue")),
	TweSupportWizard: defineAsyncComponent(() => import("~/components/tv/TweSupportWizard/TweSupportWizard.vue")),
	TweUpdateProgramCardButton: defineAsyncComponent(() => import("~/components/tv/TweUpdateProgramCardButton/TweUpdateProgramCardButton.vue")),
	TvAddressSearchOffer: defineAsyncComponent(() => import("~/components/tv/order/TvAddressSearchOffer.vue")),
	TvOrderReceipt: defineAsyncComponent(() => import("~/components/tv/order/TvOrderReceipt.vue")),
	GizmoConditionaRenderingWrapper: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/GizmoConditionaRenderingWrapper/GizmoConditionaRenderingWrapper.vue")),
	GizmoConfirmationCheck: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/GizmoConfirmationCheck/GizmoConfirmationCheck.vue")),
	GizmoFixedConfirmOrder: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/GizmoFixedConfirmOrder/GizmoFixedConfirmOrder.vue")),
	GizmoFixedOrderNextButton: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/GizmoFixedOrderNextButton/GizmoFixedOrderNextButton.vue")),
	GizmoFixedOrderRunFreetext: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/GizmoFixedOrderRunFreetext/GizmoFixedOrderRunFreetext.vue")),
	GizmoFixedOrderSteps: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/GizmoFixedOrderSteps/GizmoFixedOrderSteps.vue")),
	BroadbandServiceSettings: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandServiceSettings/BroadbandServiceSettings.vue")),
	BroadbandSubscriptionEquipment: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandSubscriptionEquipment/BroadbandSubscriptionEquipment.vue")),
	BroadbandSubscriptionServices: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandSubscriptionServices/BroadbandSubscriptionServices.vue")),
	BroadbandSubscriptionTile: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandSubscriptionTile/BroadbandSubscriptionTile.vue")),
	GizmoFaultSearchApn: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/DigitalSelvhjelp/GizmoFaultSearchApn.vue")),
	GizmoFaultSearchDiagnosis: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/DigitalSelvhjelp/GizmoFaultSearchDiagnosis.vue")),
	GizmoFaultSearchNextButton: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/DigitalSelvhjelp/GizmoFaultSearchNextButton.vue")),
	GizmoFaultSearchPreviousButton: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/DigitalSelvhjelp/GizmoFaultSearchPreviousButton.vue")),
	GizmoFaultSearchSubscriptions: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/DigitalSelvhjelp/GizmoFaultSearchSubscriptions.vue")),
	GizmoFaultSearchSummary: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/DigitalSelvhjelp/GizmoFaultSearchSummary.vue")),
	EquipmentSettings: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/EquipmentSettings/EquipmentSettings.vue")),
	FixedEquipmentList: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/FixedEquipmentList/FixedEquipmentList.vue")),
	FixedShortcutLinks: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/FixedShortcutLinks/FixedShortcutLinks.vue")),
	FixedShortcuts: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/FixedShortcuts/FixedShortcuts.vue")),
	FixedSubscriptionInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/FixedSubscriptionInfo/FixedSubscriptionInfo.vue")),
	GizmoFixedConsumerEquipmentTile: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/GizmoFixedConsumerEquipmentTile/GizmoFixedConsumerEquipmentTile.vue")),
	GizmoFixedConsumerServices: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/GizmoFixedConsumerServices/GizmoFixedConsumerServices.vue")),
	GizmoFixedConsumerSubscriptionTile: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/GizmoFixedConsumerSubscriptionTile/GizmoFixedConsumerSubscriptionTile.vue")),
	GizmoFixedOrderInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/GizmoFixedOrderInfo/GizmoFixedOrderInfo.vue")),
	GizmoFixedSubscriptionOffers: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/GizmoFixedSubscriptionOffers/GizmoFixedSubscriptionOffers.vue")),
	InstallationTile: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/InstallationTile/InstallationTile.vue")),
	ReturnEquipment: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/ReturnEquipment/ReturnEquipment.vue")),
	GizmoECommerceIdentificationStickyButton: defineAsyncComponent(() => import("~/components/ecommerce/ECommerceIdentification/children/GizmoECommerceIdentificationStickyButton.vue")),
	GizmoECommercePreConfigBundle: defineAsyncComponent(() => import("~/components/ecommerce/ECommercePreConfigBundle/Gizmo/GizmoECommercePreConfigBundle.vue")),
	GridProductsFilter: defineAsyncComponent(() => import("~/components/ecommerce/GridProducts/GridProductsFilter/GridProductsFilter.vue")),
	GridProductsRedesign: defineAsyncComponent(() => import("~/components/ecommerce/GridProducts/GridProductsRedesign/GridProductsRedesign.vue")),
	ECommerceAuth: defineAsyncComponent(() => import("~/components/ecommerce/checkout/ECommerceCheckoutAuth/ECommerceAuth.vue")),
	ECommerceEndFlowAsGuestLink: defineAsyncComponent(() => import("~/components/ecommerce/checkout/ECommerceCheckoutAuth/ECommerceEndFlowAsGuestLink.vue")),
	ECommerceCheckoutReceipt: defineAsyncComponent(() => import("~/components/ecommerce/checkout/ECommerceCheckoutReceipt/ECommerceCheckoutReceipt.vue")),
	ECommerceCheckoutSelectPaymentMethod: defineAsyncComponent(() => import("~/components/ecommerce/checkout/ECommerceCheckoutSelectPaymentMethod/ECommerceCheckoutSelectPaymentMethod.vue")),
	BackToProductPageButton: defineAsyncComponent(() => import("~/components/ecommerce/checkout/FlowButtons/BackToProductPageButton.vue")),
	CheckoutFlowNextButton: defineAsyncComponent(() => import("~/components/ecommerce/checkout/FlowButtons/CheckoutFlowNextButton.vue")),
	CheckoutFlowPrevButton: defineAsyncComponent(() => import("~/components/ecommerce/checkout/FlowButtons/CheckoutFlowPrevButton.vue")),
	GizmoTradeInCard: defineAsyncComponent(() => import("~/components/hardware-and-insurance/TradeIn/GizmoTradeInCard/GizmoTradeInCard.vue")),
	GizmoTradeInEstimatedValue: defineAsyncComponent(() => import("~/components/hardware-and-insurance/TradeIn/Steps/GizmoTradeInEstimatedValue.vue")),
	GizmoTradeInGradingStep: defineAsyncComponent(() => import("~/components/hardware-and-insurance/TradeIn/Steps/GizmoTradeInGradingStep.vue")),
	GizmoTradeInInsuranceClaim: defineAsyncComponent(() => import("~/components/hardware-and-insurance/TradeIn/Steps/GizmoTradeInInsuranceClaim.vue")),
	GizmoTradeInOrderSummary: defineAsyncComponent(() => import("~/components/hardware-and-insurance/TradeIn/Steps/GizmoTradeInOrderSummary.vue")),
	GizmoTradeinImeiSearch: defineAsyncComponent(() => import("~/components/hardware-and-insurance/TradeIn/Steps/GizmoTradeinImeiSearch.vue")),
	ECommerceSwapDamageNotCovered: defineAsyncComponent(() => import("~/components/hardware-and-insurance/checkout-flow/ECommerceSwapDamageNotCovered/ECommerceSwapDamageNotCovered.vue")),
	ECommerceTradeInConfirmation: defineAsyncComponent(() => import("~/components/hardware-and-insurance/checkout-flow/ECommerceTradeInConfirmation/ECommerceTradeInConfirmation.vue")),
	ECommerceUseSwapInsurance: defineAsyncComponent(() => import("~/components/hardware-and-insurance/checkout-flow/ECommerceUseSwapInsurance/ECommerceUseSwapInsurance.vue")),
	GizmoHardwareAndInsurancedTerminalInsurance: defineAsyncComponent(() => import("~/components/hardware-and-insurance/checkout-flow/GizmoHardwareAndInsurancedTerminalInsurance/GizmoHardwareAndInsurancedTerminalInsurance.vue")),
	MultiDeviceCTA: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/MultiDeviceCTA/MultiDeviceCTA.vue")),
	MultiDeviceInsuranceOrder: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/MultiDeviceOrder/MultiDeviceInsuranceOrder.vue")),
	GizmoInsuranceAgreementsCard: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/Shared/GizmoInsuranceAgreementsCard.vue")),
	GizmoSwapAgreementsCard: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/Shared/GizmoSwapAgreementsCard.vue")),
	AddToHomeScreenPopUp: defineAsyncComponent(() => import("~/components/platform/common/AddToHomeScreenPopUp/AddToHomeScreenPopUp.vue")),
	GizmoUXSignals: defineAsyncComponent(() => import("~/components/platform/common/GizmoUXSignals/GizmoUXSignals.vue")),
	LoginRedirectToButton: defineAsyncComponent(() => import("~/components/platform/common/LoginRedirectToButton/LoginRedirectToButton.vue")),
	RichText: defineAsyncComponent(() => import("~/components/platform/common/RichText/RichText.vue")),
	SearchContent: defineAsyncComponent(() => import("~/components/platform/common/Search/SearchContent.vue")),
	Accordion: defineAsyncComponent(() => import("~/components/platform/editorial/Accordion/Accordion.vue")),
	Accordions: defineAsyncComponent(() => import("~/components/platform/editorial/Accordions/Accordions.vue")),
	AlertMessage: defineAsyncComponent(() => import("~/components/platform/editorial/AlertMessage/AlertMessage.vue")),
	Attachments: defineAsyncComponent(() => import("~/components/platform/editorial/Attachments/Attachments.vue")),
	Banner5050: defineAsyncComponent(() => import("~/components/platform/editorial/Banner5050/Banner5050.vue")),
	BannerPromoTip: defineAsyncComponent(() => import("~/components/platform/editorial/BannerPromoTip/BannerPromoTip.vue")),
	GizmoButtons: defineAsyncComponent(() => import("~/components/platform/editorial/Button/GizmoButtons.vue")),
	CleanRender: defineAsyncComponent(() => import("~/components/platform/editorial/CleanRender/CleanRender.vue")),
	ContentGrid: defineAsyncComponent(() => import("~/components/platform/editorial/ContentGrid/ContentGrid.vue")),
	GizmoContentGridDynamic: defineAsyncComponent(() => import("~/components/platform/editorial/ContentGridDynamic/GizmoContentGridDynamic.vue")),
	DigitalAdviser: defineAsyncComponent(() => import("~/components/platform/editorial/DigitalAdviser/DigitalAdviser.vue")),
	FormDateAndTime: defineAsyncComponent(() => import("~/components/platform/editorial/FormDateAndTime/FormDateAndTime.vue")),
	Freetext: defineAsyncComponent(() => import("~/components/platform/editorial/Freetext/Freetext.vue")),
	GatewayLinksBar: defineAsyncComponent(() => import("~/components/platform/editorial/GatewayLinksBar/GatewayLinksBar.vue")),
	GdprAutofilledCustomerData: defineAsyncComponent(() => import("~/components/platform/editorial/GdprAutofilledCustomerData/GdprAutofilledCustomerData.vue")),
	GizmoVevComponent: defineAsyncComponent(() => import("~/components/platform/editorial/GizmoVevComponent/GizmoVevComponent.vue")),
	HeroBanner: defineAsyncComponent(() => import("~/components/platform/editorial/HeroBanner/HeroBanner.vue")),
	HeroBannerCarousel: defineAsyncComponent(() => import("~/components/platform/editorial/HeroBannerCarousel/HeroBannerCarousel.vue")),
	HeroBannerVideo: defineAsyncComponent(() => import("~/components/platform/editorial/HeroBannerVideo/HeroBannerVideo.vue")),
	HorizontalCards: defineAsyncComponent(() => import("~/components/platform/editorial/HorizontalCards/HorizontalCards.vue")),
	HorizontalRuler: defineAsyncComponent(() => import("~/components/platform/editorial/HorizontalRuler/HorizontalRuler.vue")),
	ImageWithCaption: defineAsyncComponent(() => import("~/components/platform/editorial/ImageWithCaption/ImageWithCaption.vue")),
	Infobox: defineAsyncComponent(() => import("~/components/platform/editorial/Infobox/Infobox.vue")),
	InformationBox: defineAsyncComponent(() => import("~/components/platform/editorial/InformationBox/InformationBox.vue")),
	Joblist: defineAsyncComponent(() => import("~/components/platform/editorial/Joblist/Joblist.vue")),
	Lightbox: defineAsyncComponent(() => import("~/components/platform/editorial/Lightbox/Lightbox.vue")),
	LinkButtonsOutlined: defineAsyncComponent(() => import("~/components/platform/editorial/LinkButtonsOutlined/LinkButtonsOutlined.vue")),
	ListBlogPostCards: defineAsyncComponent(() => import("~/components/platform/editorial/ListBlogPostCards/ListBlogPostCards.vue")),
	ListBullets: defineAsyncComponent(() => import("~/components/platform/editorial/ListBullets/ListBullets.vue")),
	ListCards: defineAsyncComponent(() => import("~/components/platform/editorial/ListCards/ListCards.vue")),
	ListCarousel: defineAsyncComponent(() => import("~/components/platform/editorial/ListCarousel/ListCarousel.vue")),
	ListCategories: defineAsyncComponent(() => import("~/components/platform/editorial/ListCategories/ListCategories.vue")),
	ListIconAndTextBar: defineAsyncComponent(() => import("~/components/platform/editorial/ListIconAndTextBar/ListIconAndTextBar.vue")),
	ListIcons: defineAsyncComponent(() => import("~/components/platform/editorial/ListIcons/ListIcons.vue")),
	ListStepByStep: defineAsyncComponent(() => import("~/components/platform/editorial/ListStepByStep/ListStepByStep.vue")),
	Null: defineAsyncComponent(() => import("~/components/platform/editorial/Null/Null.vue")),
	PersonalizationTile: defineAsyncComponent(() => import("~/components/platform/editorial/PersonalizationTile/PersonalizationTile.vue")),
	PersonalizationUserInformation: defineAsyncComponent(() => import("~/components/platform/editorial/PersonalizationUserInformation/PersonalizationUserInformation.vue")),
	QuizComponent: defineAsyncComponent(() => import("~/components/platform/editorial/QuizComponent/QuizComponent.vue")),
	Quote: defineAsyncComponent(() => import("~/components/platform/editorial/Quote/Quote.vue")),
	Receipt: defineAsyncComponent(() => import("~/components/platform/editorial/Receipt/Receipt.vue")),
	RemoteHelp: defineAsyncComponent(() => import("~/components/platform/editorial/RemoteHelp/RemoteHelp.vue")),
	ServiceMessages: defineAsyncComponent(() => import("~/components/platform/editorial/ServiceMessages/ServiceMessages.vue")),
	StickyButton: defineAsyncComponent(() => import("~/components/platform/editorial/StickyButton/StickyButton.vue")),
	SupportWizard: defineAsyncComponent(() => import("~/components/platform/editorial/SupportWizard/SupportWizard.vue")),
	Tab: defineAsyncComponent(() => import("~/components/platform/editorial/Tab/Tab.vue")),
	Table: defineAsyncComponent(() => import("~/components/platform/editorial/Table/Table.vue")),
	TeaserImageAndText: defineAsyncComponent(() => import("~/components/platform/editorial/TeaserImageAndText/TeaserImageAndText.vue")),
	Video: defineAsyncComponent(() => import("~/components/platform/editorial/Video/Video.vue")),
	VideoPlaylist: defineAsyncComponent(() => import("~/components/platform/editorial/VideoPlaylist/VideoPlaylist.vue")),
	ContactDetails: defineAsyncComponent(() => import("~/components/platform/mitt-telenor/ContactDetails/ContactDetails.vue")),
	InvoiceOverview: defineAsyncComponent(() => import("~/components/platform/mitt-telenor/InvoiceOverview/InvoiceOverview.vue")),
	GizmoSelfServiceMobileOrder: defineAsyncComponent(() => import("~/components/portfolio/SelfService/GizmoComponents/GizmoSelfServiceMobileOrder.vue")),
	GizmoSelfServiceMobileOrderConfirmation: defineAsyncComponent(() => import("~/components/portfolio/SelfService/GizmoComponents/GizmoSelfServiceMobileOrderConfirmation.vue")),
	GizmoSelfServiceMobileOrderSummary: defineAsyncComponent(() => import("~/components/portfolio/SelfService/GizmoComponents/GizmoSelfServiceMobileOrderSummary.vue")),
	SelfServiceOrder: defineAsyncComponent(() => import("~/components/portfolio/SelfService/GizmoComponents/SelfServiceOrder.vue")),
	ActivateEsimQrcode: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ActivateEsimQrcode/ActivateEsimQrcode.vue")),
	ActivateSim: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ActivateSim/ActivateSim.vue")),
	GizmoMobileAddressSelect: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/AddressSelect/GizmoMobileAddressSelect.vue")),
	ContractSummary: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ContractSummary/ContractSummary.vue")),
	FeaturedMobileServicesForSubscription: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/FeaturedMobileServicesForSubscription/FeaturedMobileServicesForSubscription.vue")),
	ManageMobileDevice: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ManageMobileDevice/ManageMobileDevice.vue")),
	ManageMobileDevicesTile: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ManageMobileDevicesTile/ManageMobileDevicesTile.vue")),
	ManageMobileSubscriptionTile: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ManageMobileSubscriptionTile/ManageMobileSubscriptionTile.vue")),
	Fargo: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileOrder/Fargo.vue")),
	ListLabel: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileOrder/ListLabel.vue")),
	MobileSubscriptions: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileOrder/MobileSubscriptions.vue")),
	ProductOrderButton: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileOrder/ProductOrderButton.vue")),
	SubscriptionChangeLabel: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileOrder/SubscriptionChangeLabel.vue")),
	MobileSubscriptionDetailsBlock: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileSubscriptionDetailsBlock/MobileSubscriptionDetailsBlock.vue")),
	MobileUsageDetails: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileUsage/MobileUsageDetails.vue")),
	MyMobileSubscriptions: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MyMobileSubscriptions/MyMobileSubscriptions.vue")),
	PersonalizationMobileUsage: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/PersonalizationMobileUsage/PersonalizationMobileUsage.vue")),
	GizmoMobileServiceConfirm: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ServiceConfirm/GizmoMobileServiceConfirm.vue")),
	GizmoMobileServiceItem: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ServiceItem/GizmoMobileServiceItem.vue")),
	GizmoMobileServiceSimcard: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ServiceSimcard/GizmoMobileServiceSimcard.vue")),
	GizmoMobileServices: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/Services/GizmoMobileServices.vue")),
	GizmoSimcardConfirm: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/SimcardConfirm/GizmoSimcardConfirm.vue")),
	FraudInsuranceHousehold: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/fraud-insurance-household/FraudInsuranceHousehold.vue")),
	FraudInsuranceHouseholdCertificate: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/fraud-insurance-household/FraudInsuranceHouseholdCertificate.vue")),
	FraudInsuranceHouseholdOrderSummary: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/fraud-insurance-household/FraudInsuranceHouseholdOrderSummary.vue")),
	FraudInsuranceIndividual: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/fraud-insurance-individual/FraudInsuranceIndividual.vue")),
	FraudInsuranceIndividualCertificate: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/fraud-insurance-individual/FraudInsuranceIndividualCertificate.vue")),
	FraudInsuranceIndividualOrderSummary: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/fraud-insurance-individual/FraudInsuranceIndividualOrderSummary.vue")),
	FraudInsuranceTerminate: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/fraud-insurance-terminate/FraudInsuranceTerminate.vue")),
	GizmoHelpCard: defineAsyncComponent(() => import("~/components/security-consumer/common/GizmoHelpCard/GizmoHelpCard.vue")),
	GizmoLinkGroup: defineAsyncComponent(() => import("~/components/security-consumer/common/GizmoLinkGroup/GizmoLinkGroup.vue")),
	EpostChangePassword: defineAsyncComponent(() => import("~/components/security-consumer/epost/EpostChangePassword/EpostChangePassword.vue")),
	EpostChangePhoneNumber: defineAsyncComponent(() => import("~/components/security-consumer/epost/EpostChangePhoneNumber/EpostChangePhoneNumber.vue")),
	EpostChangeSubscription: defineAsyncComponent(() => import("~/components/security-consumer/epost/EpostChangeSubscription/EpostChangeSubscription.vue")),
	EpostCreateNewAccount: defineAsyncComponent(() => import("~/components/security-consumer/epost/EpostCreateNewAccount/EpostCreateNewAccount.vue")),
	GizmoEpostDashboard: defineAsyncComponent(() => import("~/components/security-consumer/epost/EpostDashboard/GizmoEpostDashboard.vue")),
	EpostDeactivateAccount: defineAsyncComponent(() => import("~/components/security-consumer/epost/EpostDeactivateAccount/EpostDeactivateAccount.vue")),
	EpostSettings: defineAsyncComponent(() => import("~/components/security-consumer/epost/EpostSettings/EpostSettings.vue")),
	PremiumTvContent: defineAsyncComponent(() => import("~/components/tv/PremiumTv/PremiumTvContent/PremiumTvContent.vue")),
	FrihetAndTweNotifications: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/FrihetAndTweNotifications/FrihetAndTweNotifications.vue")),
	StreamingPlus: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/StreamingPlus/StreamingPlus.vue")),
	ThirdPartyRedirectProxy: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/ThirdPartyRedirectProxy/ThirdPartyRedirectProxy.vue")),
	TvChannelCard: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvChannelCard.vue")),
	TvContent: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContent.vue")),
	TweBoxInfoCarousel: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TweBox/TweBoxInfoCarousel.vue")),
	TweResetPinButton: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TweResetPinButton/TweResetPinButton.vue")),
	GizmoFixedConsumerCart: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerCart/GizmoFixedConsumerCart.vue")),
	GizmoFixedConsumerContactInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerContactInfo/GizmoFixedConsumerContactInfo.vue")),
	GizmoFixedConsumerEquipment: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerEquipment/GizmoFixedConsumerEquipment.vue")),
	GizmoFixedConsumerEquipmentRecommendation: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerEquipmentRecommendation/GizmoFixedConsumerEquipmentRecommendation.vue")),
	GizmoFixedConsumerInstallation: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerInstallation/GizmoFixedConsumerInstallation.vue")),
	GizmoFixedConsumerInstallationInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerInstallationInfo/GizmoFixedConsumerInstallationInfo.vue")),
	GizmoFixedConsumerOrderSummary: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerOrderSummary/GizmoFixedConsumerOrderSummary.vue")),
	GizmoFixedConsumerProductCards: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerProductCards/GizmoFixedConsumerProductCards.vue")),
	GizmoFixedConsumerReceipt: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerReceipt/GizmoFixedConsumerReceipt.vue")),
	GizmoFixedConsumerReceiptHeader: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedConsumerOrder/steps/GizmoFixedConsumerReceiptHeader/GizmoFixedConsumerReceiptHeader.vue")),
	Cart: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/Cart/Cart.vue")),
	CustomerInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/CustomerInfo/CustomerInfo.vue")),
	FixedAntennaPriceOptions: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/FixedAntennaPriceOptions/FixedAntennaPriceOptions.vue")),
	FwaPriceOptions: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/FixedAntennaPriceOptions/FwaPriceOptions.vue")),
	FixedEquipmentCards: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/FixedEquipmentCards/FixedEquipmentCards.vue")),
	FixedMainProductCards: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/FixedMainProductCards/FixedMainProductCards.vue")),
	FixedOfferCards: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/FixedOfferCards/FixedOfferCards.vue")),
	FixedProductRecommendation: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/FixedProductRecommandation/FixedProductRecommendation.vue")),
	FixedStartDateSelector: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/FixedStartDateSelector/FixedStartDateSelector.vue")),
	GizmoFixedOrderAuthentication: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/GizmoFixedOrderAuthentication/GizmoFixedOrderAuthentication.vue")),
	GizmoFixedOrderConfirmation: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/GizmoFixedOrderConfirmation/GizmoFixedOrderConfirmation.vue")),
	GizmoFixedOrderSummary: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/GizmoFixedOrderSummary/GizmoFixedOrderSummary.vue")),
	GizmoTvOnlyProductSelector: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/GizmoTvOnlyProductSelector/GizmoTvOnlyProductSelector.vue")),
	InstallationSelector: defineAsyncComponent(() => import("~/components/broadband-consumer/FixedOrder/steps/InstallationAndServices/InstallationSelector.vue")),
	GizmoBBStartBackButton: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBBStartBackButton/GizmoBBStartBackButton.vue")),
	GizmoBBStartConfirmationDialog: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBBStartConfirmationDialog/GizmoBBStartConfirmationDialog.vue")),
	GizmoBBStartOrderBar: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBBStartOrderBar/GizmoBBStartOrderBar.vue")),
	GizmoBBStartSummary: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBBStartSummary/GizmoBBStartSummary.vue")),
	GizmoBBStartTVActiveTile: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBBStartTVActiveTile/GizmoBBStartTVActiveTile.vue")),
	GizmoBBStartTVWrapper: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBBStartTVWrapper/GizmoBBStartTVWrapper.vue")),
	GizmoBBStartUserInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBBStartUserInfo/GizmoBBStartUserInfo.vue")),
	GizmoBroadBandStartDecoderSelection: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBroadBandStartDecoderSelection/GizmoBroadBandStartDecoderSelection.vue")),
	GizmoBroadBandStartTvSelector: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBroadBandStartTvSelector/GizmoBroadBandStartTvSelector.vue")),
	GizmoBroadbandStartBroadbandSpeedSelector: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBroadbandStartBroadbandSpeedSelector/GizmoBroadbandStartBroadbandSpeedSelector.vue")),
	GizmoBroadbandStartSpeedSelectorHorizontal: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/BroadbandStart/GizmoBroadbandStartBroadbandSpeedSelector/GizmoBroadbandStartSpeedSelectorHorizontal.vue")),
	GizmoFixedSubscriptionSaveValidation: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/FixedSubscriptionTermination/GizmoFixedSubscriptionSaveValidation/GizmoFixedSubscriptionSaveValidation.vue")),
	GizmoFixedSubscriptionTerminationInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/FixedSubscriptionTermination/GizmoFixedSubscriptionTerminationInfo/GizmoFixedSubscriptionTerminationInfo.vue")),
	GizmoFixedSubscriptionTerminationValidation: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/FixedSubscriptionTermination/GizmoFixedSubscriptionTerminationValidation/GizmoFixedSubscriptionTerminationValidation.vue")),
	BlockedThreatsMap: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/NettvernPlus/BlockedThreatsMap/BlockedThreatsMap.vue")),
	MostBlockedThreat: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/NettvernPlus/MostBlockedThreat/MostBlockedThreat.vue")),
	PagesBlockedInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/NettvernPlus/PagesBlockedInfo/PagesBlockedInfo.vue")),
	ReturnConfirmation: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/ReturnEquipment/ReturnConfirmation/ReturnConfirmation.vue")),
	ReturnInfo: defineAsyncComponent(() => import("~/components/broadband-consumer/mitt-telenor/ReturnEquipment/ReturnInfo/ReturnInfo.vue")),
	ECommercePhonePriceOptions: defineAsyncComponent(() => import("~/components/ecommerce/ECommercePriceOptions/children/ECommercePhonePriceOptions/ECommercePhonePriceOptions.vue")),
	TradeInOrderConfirmation: defineAsyncComponent(() => import("~/components/hardware-and-insurance/TradeIn/Steps/old-components/TradeInOrderConfirmation.vue")),
	GizmoSwapInDeviceView: defineAsyncComponent(() => import("~/components/hardware-and-insurance/checkout-flow/SwapInFlow/GizmoSwapInDeviceView/GizmoSwapInDeviceView.vue")),
	GizmoSwapInGradingStep: defineAsyncComponent(() => import("~/components/hardware-and-insurance/checkout-flow/SwapInFlow/GizmoSwapInGradingStep/GizmoSwapInGradingStep.vue")),
	GizmoSwapInInsuranceClaim: defineAsyncComponent(() => import("~/components/hardware-and-insurance/checkout-flow/SwapInFlow/GizmoSwapInInsuranceClaim/GizmoSwapInInsuranceClaim.vue")),
	SwapGrid: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/SwapOrDownPayment/SwapOrDownPaymentHub/SwapGrid.vue")),
	AccordionsXXL: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/AccordionsXXL/AccordionsXXL.vue")),
	ArticleImage: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/ArticleImage/ArticleImage.vue")),
	Button_submit: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/Button_submit/Button_submit.vue")),
	ContentOneArticle: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/ContentOneArticle/ContentOneArticle.vue")),
	FreetextWithImages: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/FreetextWithImages/FreetextWithImages.vue")),
	GreyAreaTextTeasers: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/GreyAreaTextTeasers/GreyAreaTextTeasers.vue")),
	InputCaptcha: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputCaptcha/InputCaptcha.vue")),
	InputCheckboxGroup: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputCheckboxGroup/InputCheckboxGroup.vue")),
	InputDropdown: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputDropdown/InputDropdown.vue")),
	InputFieldDate: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputFieldDate/InputFieldDate.vue")),
	InputFieldEmail: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputFieldEmail/InputFieldEmail.vue")),
	InputFieldFullName: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputFieldFullName/InputFieldFullName.vue")),
	InputFieldHidden: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputFieldHidden/InputFieldHidden.vue")),
	InputFieldPhoneNumber: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputFieldPhoneNumber/InputFieldPhoneNumber.vue")),
	InputFieldText: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputFieldText/InputFieldText.vue")),
	InputFieldTime: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputFieldTime/InputFieldTime.vue")),
	InputRadioButtonGroup: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputRadioButtonGroup/InputRadioButtonGroup.vue")),
	InputTextarea: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputTextarea/InputTextarea.vue")),
	InputTextfieldOrgnr: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/InputTextfieldOrgnr/InputTextfieldOrgnr.vue")),
	Input_file: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/Input_file/Input_file.vue")),
	LinkIconAndText: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/LinkIconAndText/LinkIconAndText.vue")),
	ListCompareColumns: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/ListCompareColumns/ListCompareColumns.vue")),
	ListSubscriptionCards: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/ListSubscriptionCards/ListSubscriptionCards.vue")),
	OldImage: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/OldImage/OldImage.vue")),
	SausageSimple: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/SausageSimple/SausageSimple.vue")),
	StickyButtons: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/StickyButtons/StickyButtons.vue")),
	TeaserImageAndText5050: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/TeaserImageAndText5050/TeaserImageAndText5050.vue")),
	TeaserReadMore: defineAsyncComponent(() => import("~/components/platform/editorial/FallbackComponents/TeaserReadMore/TeaserReadMore.vue")),
	GizmoTabs: defineAsyncComponent(() => import("~/components/platform/editorial/Tabs/GizmoTabs/GizmoTabs.vue")),
	GizmoFamilyMemberInvites: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/Family/FamilyMemberInvites/GizmoFamilyMemberInvites.vue")),
	GizmoFamilyMemberUsage: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/Family/FamilyMemberUsage/GizmoFamilyMemberUsage.vue")),
	GizmoFamilyMyAgreement: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/Family/FamilyMyAgreement/GizmoFamilyMyAgreement.vue")),
	GizmoFamilyMyUsage: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/Family/FamilyMyUsage/GizmoFamilyMyUsage.vue")),
	GizmoFamilyProducts: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/Family/FamilyProducts/GizmoFamilyProducts.vue")),
	MobileSubscriptionSettings: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/ManageMobileSubscriptionTile/MobileSubscriptionSettings/MobileSubscriptionSettings.vue")),
	SubscriptionCalculatorFargo: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/MobileOrder/FargoCalculator/SubscriptionCalculatorFargo.vue")),
	ChargePrepaid: defineAsyncComponent(() => import("~/components/portfolio/mitt-telenor/PersonalizationMobileUsage/ChargePrepaid/ChargePrepaid.vue")),
	FraudInsuranceLinks: defineAsyncComponent(() => import("~/components/security-consumer/FraudInsurance/components/fraud-insurance-links/FraudInsuranceLinks.vue")),
	K2Certificate: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/certificate/K2Certificate.vue")),
	SafeDashboardRedirect: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/dashboard/SafeDashboardRedirect.vue")),
	K2OrderFlow: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/order-flow/K2OrderFlow.vue")),
	K2Terminate: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/terminate/K2Terminate.vue")),
	PremiumTvContentAdministrateProduct: defineAsyncComponent(() => import("~/components/tv/PremiumTv/PremiumTvContent/PremiumTvContentAdministrateProduct/PremiumTvContentAdministrateProduct.vue")),
	PremiumTvContentCancelProduct: defineAsyncComponent(() => import("~/components/tv/PremiumTv/PremiumTvContent/PremiumTvContentCancelProduct/PremiumTvContentCancelProduct.vue")),
	PremiumTvContentConfirmOrder: defineAsyncComponent(() => import("~/components/tv/PremiumTv/PremiumTvContent/PremiumTvContentConfirmOrder/PremiumTvContentConfirmOrder.vue")),
	PremiumTvContentProductInfo: defineAsyncComponent(() => import("~/components/tv/PremiumTv/PremiumTvContent/PremiumTvContentProductInfo/PremiumTvContentProductInfo.vue")),
	PremiumTvContentSummary: defineAsyncComponent(() => import("~/components/tv/PremiumTv/PremiumTvContent/PremiumTvContentSummary/PremiumTvContentSummary.vue")),
	FrihetBroadbandSpeedSelector: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetBroadbandSpeedSelector/FrihetBroadbandSpeedSelector.vue")),
	FrihetPointsAndEquipmentPriceConfirmation: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetPointsAndEquipmentPriceConfirmation/FrihetPointsAndEquipmentPriceConfirmation.vue")),
	FrihetSaveButton: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetSaveButton/FrihetSaveButton.vue")),
	FrihetSummary: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetSummary/FrihetSummary.vue")),
	FrihetTvEquipmentSelector: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetTvEquipmentSelector/FrihetTvEquipmentSelector.vue")),
	FrihetTvSelector: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetTvSelector/FrihetTvSelector.vue")),
	FrihetTvStartSetupSelector: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetTvStartSetupSelector/FrihetTvStartSetupSelector.vue")),
	FrihetTweToggleWrapper: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetTweToggleWrapper/FrihetTweToggleWrapper.vue")),
	FrihetTypeToggleWrapper: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/Frihet/FrihetTypeToggleWrapper/FrihetTypeToggleWrapper.vue")),
	StreamingPlusPriceConfirmation: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/StreamingPlus/StreamingPlusPriceConfirmation/StreamingPlusPriceConfirmation.vue")),
	StreamingPlusProviderInfo: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/StreamingPlus/StreamingPlusProviderInfo/StreamingPlusProviderInfo.vue")),
	StreamingPlusStripeCardPayment: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/StreamingPlus/StreamingPlusStripeCardPayment/StreamingPlusStripeCardPayment.vue")),
	StreamingPlusSummary: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/StreamingPlus/StreamingPlusSummary/StreamingPlusSummary.vue")),
	StreamingPlusSwitchProvider: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/StreamingPlus/StreamingPlusSwitchProvider/StreamingPlusSwitchProvider.vue")),
	TvContentActivateThirdPartyUser: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentActivateThirdPartyUser/TvContentActivateThirdPartyUser.vue")),
	TvContentChannelPositions: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentChannelPositions/TvContentChannelPositions.vue")),
	TvContentConflictCompensation: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentConflictCompensation/TvContentConflictCompensation.vue")),
	TvContentPickerTweBoxNav: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentPicketTweBoxNav/TvContentPickerTweBoxNav.vue")),
	TvContentPointBalance: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentPointBalance/TvContentPointBalance.vue")),
	TvContentPriceConfirmation: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentPriceConfirmation/TvContentPriceConfirmation.vue")),
	TvContentRecoverThirdPartyUser: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentRecoverThirdPartyUser/TvContentRecoverThirdPartyUser.vue")),
	TvContentRentedMovies: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentRentedMovies/TvContentRentedMovies.vue")),
	TvContentSaveAndChanges: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentSaveAndChanges/TvContentSaveAndChanges.vue")),
	TvContentSaveButton: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentSaveButton/TvContentSaveButton.vue")),
	TvContentSummary: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentSummary/TvContentSummary.vue")),
	TvContentTweBoxShoppingCart: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TvContentTweBoxShoppingCart/TvContentTweBoxShoppingCart.vue")),
	TweResetToDefaultSetupButton: defineAsyncComponent(() => import("~/components/tv/mitt-telenor/TvContent/TweResetToDefaultSetupButton/TweResetToDefaultSetupButton.vue")),
	GizmoInuranceUpgradeOrDowngrade: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/Insurance/Hub/ChangeInsurance/GizmoInuranceUpgradeOrDowngrade.vue")),
	InsuranceDetails: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/Insurance/Hub/Details/InsuranceDetails.vue")),
	InsuranceGrid: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/Insurance/Hub/Grid/InsuranceGrid.vue")),
	InsuranceHub: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/Insurance/Hub/Grid/InsuranceHub.vue")),
	GizmoWarningCenter: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/components/GizmoWarningCenter/GizmoWarningCenter.vue")),
	GizmoCreditConsent: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/credit-guard/GizmoCreditConsent/GizmoCreditConsent.vue")),
	GizmoCreditGuardHomePage: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/credit-guard/GizmoCreditGuardHomePage/GizmoCreditGuardHomePage.vue")),
	GizmoSpecificCredit: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/credit-guard/GizmoSpecificCredit/GizmoSpecificCredit.vue")),
	GizmoSafePlusProductCard: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/dashboard/GizmoSafePlusProductCard/GizmoSafePlusProductCard.vue")),
	GizmoSafePlusProducts: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/dashboard/GizmoSafePlusProducts/GizmoSafePlusProducts.vue")),
	GizmoSafeProductHome: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/dashboard/GizmoSafeProductHome/GizmoSafeProductHome.vue")),
	K2FraudInsurance: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/fraud-insurance/dashboard/K2FraudInsurance.vue")),
	K2FraudInsuranceLinks: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/fraud-insurance/fraud-insurance-links/K2FraudInsuranceLinks.vue")),
	K2FraudInsuranceTerminate: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/fraud-insurance/terminate/K2FraudInsuranceTerminate.vue")),
	GizmoEmailVerification: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/id-guard/GizmoEmailVerification/GizmoEmailVerification.vue")),
	GizmoIDGuardAddEmail: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/id-guard/GizmoIDGuardAddEmail/GizmoIDGuardAddEmail.vue")),
	GizmoIDGuardAdminister: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/id-guard/GizmoIDGuardAdminister/GizmoIDGuardAdminister.vue")),
	GizmoIDGuardArchive: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/id-guard/GizmoIDGuardArchive/GizmoIDGuardArchive.vue")),
	GizmoIDGuardBreach: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/id-guard/GizmoIDGuardBreach/GizmoIDGuardBreach.vue")),
	GizmoIDGuardDashboard: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/id-guard/GizmoIDGuardDashboard/GizmoIDGuardDashboard.vue")),
	K2OrderSummary: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/order-flow/order-summary/K2OrderSummary.vue")),
	InsuranceChooseInsurance: defineAsyncComponent(() => import("~/components/hardware-and-insurance/mitt-telenor/Insurance/Hub/ChangeInsurance/children/InsuranceChooseInsurance.vue")),
	IDGuardAdministerRemoveEmail: defineAsyncComponent(() => import("~/components/security-consumer/K2/mitt-telenor/id-guard/GizmoIDGuardAdminister/IDGuardAdministerRemoveEmail/IDGuardAdministerRemoveEmail.vue")),
}