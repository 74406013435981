import type { InternalAxiosRequestConfig } from "axios";
import type { CacheRequestConfig } from "axios-cache-interceptor";

export const getCacheKey = (config: CacheRequestConfig | InternalAxiosRequestConfig): string => {
	const { method, url, params, baseURL } = config;

	const fullUrl = baseURL + url;

	const urlWithParams = appendParamsToUrl(fullUrl, params);

	return `${method?.toUpperCase()}:${urlWithParams}`;
};

const appendParamsToUrl = (url: string, params) => {
	if (!params) return url;

	const queryString = new URLSearchParams(params).toString();

	if (!queryString) return url;

	return `${url}?${queryString}`;
};
