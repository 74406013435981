import addressSearch from "~/integrations/address-search";
import type { Address } from "@telenornorgeinternal/fixed-save-api";

type AddressType = "M" | "P" | "O";
interface Suggestion extends Address {
	formattedAddress: string;
}

export const useAddressStore = defineStore("address", () => {
	const address = ref<Address | null>(null);

	const addressType = computed<AddressType | string>(() => {
		return address.value?.addressType || "";
	});

	const cadastralAddress = computed<Address | null>(() => {
		return addressType.value === "M" ? address.value : null;
	});

	const lastSearchedAddress = ref<string>("");

	const isLoggedIn = computed(() => useUserStore().isUserLoggedIn);

	const errorFetchingAddress = ref<Error | null>(null);

	const loadingAddress = ref(false);

	async function fetchAddress(addressId: string): Promise<Address> {
		try {
			loadingAddress.value = true;
			const resp = (await addressSearch.fetchAddress({
				addressId: addressId,
				isLoggedIn: isLoggedIn.value,
			})) as Address;
			address.value = resp;
			return resp;
		} catch (err) {
			errorFetchingAddress.value = err;
			console.error(err);
		} finally {
			loadingAddress.value = false;
		}
	}

	const apartments = ref<Address[]>([]);
	const errorFetchingApartments = ref<Error | null>(null);

	const loadingApartments = ref(false);

	async function fetchAddressAndApartments(addressId: string): Promise<Address[]> {
		await fetchAddress(addressId);
		errorFetchingApartments.value = null;
		try {
			loadingApartments.value = true;
			const response = await addressSearch.fetchApartments({ address: address.value, isLoggedIn: isLoggedIn.value });
			const addresses = response.addresses as Address[];
			apartments.value = addresses;

			if (addresses.length === 1) {
				address.value = addresses[0];
			}

			return apartments.value;
		} catch (error) {
			console.error(error);
			errorFetchingApartments.value = error;

			return [];
		} finally {
			loadingApartments.value = false;
		}
	}

	const loadingSuggestions = ref(false);
	const suggestions = ref<Suggestion[]>([]);
	const errorFetchingSuggestions = ref<Error | null>(null);

	async function fetchSuggestions(query: string): Promise<Suggestion[]> {
		try {
			loadingSuggestions.value = true;
			const response = await addressSearch.searchWithBFF(query);
			suggestions.value = response.addresses.map((suggestion) => {
				return {
					...suggestion,
					formattedAddress: suggestion?.address
						?.replace(/^\w/, (m) => m.toUpperCase())
						?.replace(/[\s.][\w]/g, (m) => m.toUpperCase()),
				};
			});
		} catch (error) {
			console.error(error);
			errorFetchingSuggestions.value = error;
		} finally {
			loadingSuggestions.value = false;
		}

		return suggestions.value;
	}

	function resetData() {
		address.value = null;
		suggestions.value = [];
		errorFetchingSuggestions.value = null;
		apartments.value = [];
		errorFetchingApartments.value = null;
		lastSearchedAddress.value = "";
	}

	function setAddress(adr: Address) {
		address.value = adr;
	}

	const loading = computed<boolean>(() => {
		return loadingAddress.value || loadingApartments.value || loadingSuggestions.value;
	});

	const error: ComputedRef<Error | null> = computed(() => {
		return errorFetchingAddress.value || errorFetchingApartments.value || errorFetchingSuggestions.value || null;
	});

	return {
		suggestions,
		errorFetchingSuggestions,
		apartments,
		errorFetchingApartments,
		lastSearchedAddress,
		loading,
		fetchAddress,
		fetchAddressAndApartments,
		fetchSuggestions,
		resetData,
		setAddress,
		addressType,
		cadastralAddress,
		loadingAddress,
		loadingApartments,
		loadingSuggestions,
		address,
		error,
	};
});
